const TOKEN_KEY = 'cw-au';
const TOKEN_LIFETIME = 1209600000;
const Common = {
    localStorageSetToken: (aUserID, aToken, aStaffID, aStaffName) => {
        const userInfo = {
            timeExpired: Date.now() + TOKEN_LIFETIME,
            aUserID: aUserID,
            aToken: aToken,
            aStaffID: aStaffID,
            aStaffName: aStaffName

        }
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(userInfo));
    },
    localStorageGetToken: () => {
        const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || {};
        const returnInfo = {
            aUserID: '',
            aToken: '',
            aStaffID: '',
            aStaffName: ''
        }
        const timeCurrent = Date.now();
        const timeExpired = userInfo.timeExpired || 0;
        if (timeExpired > timeCurrent) {
            returnInfo.aUserID = userInfo.aUserID || '';
            returnInfo.aToken = userInfo.aToken || '';
            returnInfo.aStaffID = userInfo.aStaffID || '';
            returnInfo.aStaffName = userInfo.aStaffName || '';
        }

        return returnInfo;

    },

    localStorageRemoveToken: () => {
        window.localStorage.removeItem(TOKEN_KEY);
    },

    localStorageGetStaff: () => {
        const userInfo = JSON.parse(window.localStorage.getItem(TOKEN_KEY)) || {};
        return { staffID: userInfo.aStaffID || '', staffName: userInfo.aStaffName || '' };
    },

    BASE_NAME: '',
    URL_HOME: '/project',
    URL_LOGIN: '/login',
    BASE_URL_API: 'https://cw.morien-paint.com/api',      //base url of api
    // BASE_URL_API: 'http://cw.localhost:8082/api',      //base url of api
}

export default Common
